
    import Quiz from "@/components/quiz.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            Quiz,
        },
    })

    // *****************************************************************************************************************
    export default class DevUnrealBlueprintsQuizzes extends Vue {
        // Data function
        private data() {
            return {
                materialsQuiz01:
                    require("@/assets/quizzes/unreal-materials-quiz-01.json"),

                materialsQuiz02:
                    require("@/assets/quizzes/unreal-materials-quiz-02.json"),

                materialsQuiz03:
                    require("@/assets/quizzes/unreal-materials-quiz-03.json"),

                materialsQuiz04:
                    require("@/assets/quizzes/unreal-materials-quiz-04.json"),

                materialsQuiz05:
                    require("@/assets/quizzes/unreal-materials-quiz-05.json"),

                materialsQuiz06:
                    require("@/assets/quizzes/unreal-materials-quiz-06.json"),

                materialsQuiz07:
                    require("@/assets/quizzes/unreal-materials-quiz-07.json"),

                unrealIconPathAndName:
                    require("@/assets/images/unreal.png"),

                unrealMaterialEditor01PathAndName:
                    require("@/assets/images/unreal_material_editor_01.png"),

                unrealBlueprintVariable01PathAndName:
                    require("@/assets/images/unreal_blueprint_variable_01.png"),

                unrealConstructionScript01PathAndName:
                    require("@/assets/images/unreal_construction_script_01.png"),

                unrealMaterialExpressionNodes01PathAndName:
                    require("@/assets/images/unreal_material_expression_nodes_01.png"),

                unrealMaterialExpressionNodes02PathAndName:
                    require("@/assets/images/unreal_material_expression_nodes_02.png"),

                unrealMaterialExpressionNodes03PathAndName:
                    require("@/assets/images/unreal_material_expression_nodes_03.png"),

                unrealMaterialSubsurfaceColor01PathAndName:
                    require("@/assets/images/unreal_material_subsurface_color_01.png"),

                unrealMaterialSubsurfaceProfile01PathAndName:
                    require("@/assets/images/unreal_material_subsurface_profile_01.png"),

                unrealMaterialShadingModelThinTranslucent01PathAndName:
                    require("@/assets/images/unreal_material_shading_model_thin_translucent_01.png"),
            };
        }
    }
